import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Row, Table } from 'react-bootstrap';
import * as CONST from '../../../includes/Constant';
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT, SD_GET_YEAR_DATA } from '../../../includes/staticData';
import Select from 'react-select';
import useAuthInfo from '../../../../hooks/IsAdmin';
import { toast } from 'react-toastify';

export default function FixedcostMonthlySummary() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [financialYears, setFinancialYears] = useState([]);
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const { YEAR_LIST, CURRENT_FINANCIAL_YEAR, SELECTED_YEAR } = SD_GET_YEAR_DATA();
  const [departmentList, setDepartmentList] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [currentFinancialYears, setCurrentFinancialYears] = useState([]);

  useEffect(() => {
    if (YEAR_LIST) { setFinancialYears(YEAR_LIST); }
    if (CURRENT_FINANCIAL_YEAR) { setCurrentFinancialYears(CURRENT_FINANCIAL_YEAR); }
    if (SELECTED_YEAR) { setSelectedYear(SELECTED_YEAR); }
    if (isAdmin) { fetchDepartmentList(); }

    const defaultParams = {
      year: CURRENT_FINANCIAL_YEAR,
      department_id: isAdmin ? "" : eDepartmentId,
    };
    setSearchParams(defaultParams);
    fetchData(defaultParams);
  }, []);

  const fetchDepartmentList = async () => {
    try {
      const departmentResponse = await axios.get(CONST.GET_DEPARTMENT_LIST);
      if (departmentResponse.data.status === 'success') {
        setDepartmentList(departmentResponse.data.data.map(dept => ({ value: dept.id, label: dept.name })));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };
  const fetchData = async (param) => {
    try {
      setLoading(true);
      const response = await axios.get(CONST.DASH_FIXED_MONTHLY_SUMMARY, {
        params: {
          year: param.year,
          department_id: param?.department_id || ""
        }
      });
      setData(response.data.data || []);
    } catch (error) {
      console.error('Error fetching financial summary:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
  };
  const handleDepartmentChange = (selectedOption) => {
    setDepartments(selectedOption);
  };

  const handleSearch = () => {

    const newParams = {
      year: selectedYear ? selectedYear.value : "",
      department_id: isAdmin ? departments?.value : eDepartmentId,
    };
    setSearchParams(newParams);
    fetchData(newParams);
  };
  return (
    <div className="container">
      <div className="row align-items-center mb-3">
        <Col className='col-3'>
          <Select
            options={financialYears}
            placeholder="Financial Year..."
            onChange={handleYearChange}
            value={selectedYear}
          />
        </Col>
        {isAdmin && (
          <Col className='col-3'>
            <Select
              options={departmentList}
              placeholder="Department..."
              onChange={handleDepartmentChange}
              value={departments}
            />
          </Col>
        )}
        <Col xs="auto">
          <Button variant="primary" onClick={handleSearch}>
            <i className="bx bx-search-alt"></i> Search
          </Button>
        </Col>


      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <Table striped bordered hover responsive className='smalltable'>
          <thead className="bg-gray-100">
            <tr>
              <th>Month</th>
              <th>Raised</th>
              <th>Received</th>
              <th>Considered</th>
              <th>Fees</th>
</tr>
          </thead>
          <tbody>
            {data.slice().reverse().map((item, idx) => (
              <tr key={idx}>
                <td className='text-center'>{item.month}</td>
                <td className='text-right'>{S_FORMAT_INR_CURRENCY_AND_AMOUNT(item.raised || 0)}</td>
                <td className='text-right'>{S_FORMAT_INR_CURRENCY_AND_AMOUNT(item.received || 0)}</td>
                <td className='text-right'>{S_FORMAT_INR_CURRENCY_AND_AMOUNT(item.considered || 0)}</td>
                <td className='text-right'>{S_FORMAT_INR_CURRENCY_AND_AMOUNT(item.platform_fee || 0)}</td>

              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}