import React, { useEffect, useState } from "react";
import axios from "axios";

import Loading from "react-fullscreen-loading";
import * as CONST from "../../includes/Constant";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import useAuthInfo from "../../../hooks/IsAdmin";
import { SD_GET_YEAR_DATA, SD_FY_MONTHS } from "../../includes/staticData";
import InvoiceStaus from "./boards/InvoiceStaus";
import DashboardCard from "./boards/DashboardCard";
import StatsVendorPayments from "./stats/StatsVendorPayments";
import InvoiceStatusLineChart from "./graph/InvoiceStatusLineChart";
import TopPendingInvoices from "./stats/TopPendingInvoices";
 
import RaisedVsReceivedVsFee from "./graph/RaisedVsReceivedVsFee";
import FixedcostVsDedicatedBarChart from "./graph/FixedcostVsDedicatedBarChart";


const Dashboard = () => {
  const { isAdmin, eDepartmentId } = useAuthInfo();
  const { YEAR_LIST, CURRENT_FINANCIAL_YEAR, CURRENT_YEAR, SELECTED_YEAR } = SD_GET_YEAR_DATA();

  const [financialYear, setFinancialYear] = useState(null);
  const [raisedVsReceived, setRaisedVsReceived] = useState(null);
  const [dedicatedProjects, setDedicatedProjects] = useState(null);
  const [fixedcostProjects, setFixedcostProjects] = useState(null);
  const [vendorsSummery, setVendorsSummery] = useState(null);
  const [fixedcostVsDedicated, setFixedcostVsDedicated] = useState(null);
  const [raisedAmount, setRaisedAmount] = useState(null);
  const [receivedAmount, setReceivedAmount] = useState(null);
  const [consideredAmount, setConsideredAmount] = useState(null);
  const [financialYears, setFinancialYears] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [selectedYear, setSelectedYear] = useState(null);
  const [currentFinancialYears, setCurrentFinancialYears] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [loading, setLoading] = useState({
    raisedVsReceived: true,
    vendors: true,
    loadingDedicatedProject: true,
    loadingFixedcostProjects: true,
    considered: true,
  });

  useEffect(() => {
    if (YEAR_LIST) setFinancialYears(YEAR_LIST);
    if (CURRENT_FINANCIAL_YEAR) setCurrentFinancialYears(CURRENT_FINANCIAL_YEAR);
    if (SELECTED_YEAR) setSelectedYear(SELECTED_YEAR);
    if (isAdmin) fetchDepartmentList();

    const getDefaultMonth = () => {
      const jsCurrentMonth = new Date().getMonth() + 1; // JavaScript months are 0-based, so add 1
      console.log("JS Current Month:", jsCurrentMonth);
      const fyMonthMap = {
        1: 10,  // Jan -> 10
        2: 11,  // Feb -> 11
        3: 12,  // Mar -> 12
        4: 1,   // Apr -> 1
        5: 2,   // May -> 2
        6: 3,   // Jun -> 3
        7: 4,   // Jul -> 4
        8: 5,   // Aug -> 5
        9: 6,   // Sep -> 6
        10: 7,  // Oct -> 7
        11: 8,  // Nov -> 8
        12: 9   // Dec -> 9
      };
      const financialMonth = fyMonthMap[jsCurrentMonth]; // Convert JS month to financial year month
      const defaultMonth = SD_FY_MONTHS.find((m) => m.value === financialMonth);
      setCurrentMonth(defaultMonth);
      return defaultMonth || null;
    };
    setSelectedMonth(getDefaultMonth());
    console.log(getDefaultMonth());
    const defaultParams = {
      year: CURRENT_FINANCIAL_YEAR,
      department_id: isAdmin ? "" : eDepartmentId,
      month: getDefaultMonth().value || "",
    };
    setSearchParams(defaultParams);
    fetchAllAPI(defaultParams);
  }, []);

  const fetchDepartmentList = async () => {
    try {
      const departmentResponse = await axios.get(CONST.GET_DEPARTMENT_LIST);
      if (departmentResponse.data.status === 'success') {
        const departments = departmentResponse.data.data.map(dept => ({
          value: dept.id,
          label: dept.name,
        }));
        setDepartmentList([{ value: '', label: 'Select' }, ...departments]);
      }
    } catch (error) {
      toast.error('Error fetching departments');
    }
  };

  const fetchRaisedVsReceived = async (params) => {
    console.log(params);
    setLoading(prev => ({ ...prev, raisedVsReceived: true }));
    try {
      const res = await axios.get(CONST.DASH_RAISED_VS_RECEIVED, { params });
      setRaisedVsReceived({
        totalRaised: res.data.total_raised,
        totalReceived: res.data.total_received
      });
    } catch (err) {
      console.error("Error fetching raised vs received data");
    } finally {
      setLoading(prev => ({ ...prev, raisedVsReceived: false }));
    }
  };
  const fetchVendorsSummery = async (params) => {
    setLoading(prev => ({ ...prev, vendors: true }));
    try {
      const res = await axios.get(CONST.DASH_VENDOR_SUMMERY, { params });
      setVendorsSummery(res.data.data);
    } catch (err) {
      console.error("Error fetching vendor summary");
    } finally {
      setLoading(prev => ({ ...prev, vendors: false }));
    }
  };
  const fetchFixedVsDedicated = async (params) => {
    console.log(params);
    setLoading(prev => ({ ...prev, loadingDedicatedProject: true }));
    setLoading(prev => ({ ...prev, loadingFixedcostProjects: true }));
    try {
      const res = await axios.get(CONST.DASH_FIXED_VS_DEDICATED, { params });
      setFixedcostVsDedicated({
        dedicated: res.data.dedicated,
        fixedcost: res.data.fixedcost
      });
      setDedicatedProjects({
        totalRaised: res.data.dedicated.total_raised,
        totalReceived: res.data.dedicated.total_received
      })
      setFixedcostProjects({
        totalRaised: res.data.fixedcost.total_raised,
        totalReceived: res.data.fixedcost.total_received
      })
    } catch (err) {
      console.error("Error fetching raised vs received data");
    } finally {
      setLoading(prev => ({ ...prev, loadingDedicatedProject: false }));
      setLoading(prev => ({ ...prev, loadingFixedcostProjects: false }));
    }
  };
  const fetchAllAPI = (params) => {
    console.log(params);
    fetchRaisedVsReceived(params);
    fetchVendorsSummery(params);
    fetchFixedVsDedicated(params);
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const handleDepartmentChange = (selectedOption) => {
    setDepartments(selectedOption);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };

  const handleSearch = () => {
    const newParams = {
      year: selectedYear ? selectedYear.value : "",
      department_id: isAdmin ? departments?.value || "" : eDepartmentId,
      month: selectedMonth ? selectedMonth.value : "",
    };
    setSearchParams(newParams);
    fetchAllAPI(newParams);
  };

  const handleReset = () => {
    console.log(currentMonth);
    console.log(selectedMonth);
    setDepartments(null);
    setSelectedMonth(currentMonth || "");
    const resetParams = {
      year: CURRENT_FINANCIAL_YEAR,
      department_id: isAdmin ? "" : eDepartmentId,
      month: currentMonth?.value || "",
    };
    setSearchParams(resetParams);
    fetchAllAPI(resetParams);
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mb-4">
          <div className="col-12 d-flex justify-content-end">
            <Row className="  justify-content-end w-100">
              <Col xl={3} md={4} sm={6}>
                <Select
                  options={financialYears}
                  placeholder="Financial Year..."
                  onChange={handleYearChange}
                  value={selectedYear}
                />
              </Col>

              {isAdmin && (
                <Col xl={3} md={4} sm={6}>
                  <Select
                    options={departmentList}
                    placeholder="Department..."
                    onChange={handleDepartmentChange}
                    value={departments}
                  />
                </Col>
              )}

              <Col xl={3} md={4} sm={6}>
                <Select
                  options={SD_FY_MONTHS}
                  placeholder="Select Month"
                  onChange={handleMonthChange}
                  value={selectedMonth}
                />
              </Col>

              <Col xl="auto" className="d-flex align-items-center">
                <Button variant="primary" onClick={handleSearch} className="me-2">
                  <i className="bx bx-search-alt"></i> Search
                </Button>
                <Button variant="secondary" onClick={handleReset}>
                  <i className="bx bx-x"></i> Reset
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <div className="row mb-5">
              <DashboardCard
                title="Cashflow"
                values={[
                  { label: "Raised", amount: raisedVsReceived?.totalRaised },
                  { label: "Received", amount: raisedVsReceived?.totalReceived }
                ]}
                loading={loading.raisedVsReceived}
                view={'raisedVsReceived'}
                split={true}
                modal_size='xl'
              />
              <DashboardCard
                title="Dedicated Projects"
                values={[
                  { label: "Raised", amount: dedicatedProjects?.totalRaised },
                  { label: "Received", amount: dedicatedProjects?.totalReceived }
                ]}
                loading={loading.loadingDedicatedProject}
                view={'dedicatedMonthlySummary'}
                modal_size='lg'
              />
              <DashboardCard
                title="Fixcost Projects"
                values={[
                  { label: "Raised", amount: fixedcostProjects?.totalRaised },
                  { label: "Received", amount: fixedcostProjects?.totalReceived }
                ]}
                loading={loading.loadingFixedcostProjects}
                view={'fixedcostMonthlySummary'}
                modal_size='lg'
              />
              <DashboardCard
                title="Vendors Payout"
                values={[
                  { label: "Monthly Payments", amount: vendorsSummery },
                ]}
                loading={loading.vendors}
                view={'vendorsSummery'}
                modal_size='md'
              />
            </div>
          </div>
          <div className="col-xl-8 col-md-8 col-sm-12 mb-3">
            <InvoiceStatusLineChart searchParams={searchParams} />
          </div>
          <div className="col-xl-4 col-md-4 col-sm-12 mb-3">
            <RaisedVsReceivedVsFee searchParams={searchParams} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <FixedcostVsDedicatedBarChart searchParams={searchParams} />
          </div>
          <div className="col-xl-4 col-md-4 col-sm-12 mb-3">

          </div>
        </div>

        {/*   <div className="row">
          <div className="col-xl-6 col-xxl-5 d-flex">
            <InvoiceStaus searchParams={searchParams} />
          </div>
        </div> */}

        <div className="row mb-5">
          <div className="col-xl-7 col-md-7 col-sm-12 mb-3">
            <TopPendingInvoices searchParams={searchParams} />
          </div>
          <div className="col-xl-5 col-md-5 col-sm-12 mb-3">
            <StatsVendorPayments searchParams={searchParams} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
