import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

import BlockLoading from "../../../blocks/BlockLoading";
import * as CONST from "../../../includes/Constant";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../../includes/staticData";
import { Link } from "react-router-dom";
import { setGlobalModal } from "../../modal/GlobalModal";
import RaisedVsReceivedVsFeeMonthlySummary from "../stats/RaisedVsReceivedVsFeeMonthlySummary";

ChartJS.register(ArcElement, Tooltip, Legend);

const COLORS = ["#0088FE", "#FF0000", "#00C49F"];

const RaisedVsReceivedVsFee = ({ searchParams }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRaisedVsReceivedVsFee = async () => {
    try {
      setLoading(true);
      const response = await axios.get(CONST.DASH_RAISED_VS_RECEIVED_VS_FEE, {
        params: {
          year: searchParams.year,
          department_id: searchParams?.department_id || ""
        }
      });

      const data = response.data;
      if (data) {
        const totalRaised = parseFloat(data.total_raised || 0);
        const totalReceived = parseFloat(data.total_received || 0);
        const totalFee = parseFloat(data.total_fee || 0);

        const formattedData = [
          { name: "Raised", value: totalRaised },
          { name: "Fee", value: totalFee },
          { name: "Received", value: totalReceived },
        ];

        setChartData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRaisedVsReceivedVsFee();
  }, [searchParams]);

  const hasData =
    chartData.length > 0 &&
    chartData.some((item) => Number(item.value) > 0);

  const data = {
    labels: chartData.map((entry) => entry.name),
    datasets: [
      {
        data: chartData.map((entry) => entry.value),
        backgroundColor: COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            const label = context.label || "";
            return `${label}: ${S_FORMAT_INR_CURRENCY_AND_AMOUNT(value)}`;
          },
        },
      },
      legend: {
        display: false, // You can turn this on if you want a default legend
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="card flex-fill w-100">
      <div className="card-header">
        <h5 className="card-title mb-0">
          FY Movement - Fees
          <span className="badge badge-primary-light">
            <Link
              to="#"
              className="action"
              onClick={() =>
                setGlobalModal(
                  RaisedVsReceivedVsFeeMonthlySummary,
                  {},
                  {
                    size: "xl",
                    title: "Raised vs Received vs Platform Fees - Monthly Summary"
                  }
                )
              }
            >
              <i className="bx bx-info-circle"></i>
            </Link>
          </span>
        </h5>
      </div>
      <div className="card-body pt-2 pb-3">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 d-flex justify-content-center">
            {loading ? (
              <BlockLoading />
            ) : hasData ? (
              <div className="w-100 d-flex flex-column align-items-center">
                <div className="d-flex justify-content-center flex-wrap mb-3">
                  {chartData.map((entry, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px 15px",
                      }}
                    >
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: COLORS[index],
                          marginRight: "6px",
                          borderRadius: "2px",
                        }}
                      ></div>
                      <strong>{entry.name}:</strong>
                      <span style={{ marginLeft: "5px" }}>
                        {S_FORMAT_INR_CURRENCY_AND_AMOUNT(entry.value)}
                      </span>
                    </div>
                  ))}
                </div>
                <div style={{ width: 250, height: 250 }}>
                  <Pie data={data} options={options} />
                </div>
              </div>
            ) : (
              <h5 className="text-muted text-center">No data to show</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaisedVsReceivedVsFee;
