import React, { useContext } from 'react'

import Login from '../auth/Login';

import { Navigate, Route, Routes } from 'react-router-dom';
import AuthRoutes from "../auth/AuthRoute";
import * as CONST from '../includes/Constant'
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
//import ChangePassword from "../admin/profile/ChangePassword";
import Profile from '../admin/profile/Profile';
import UserList from "../admin/user/UserList";
import Setting from '../admin/setting/Setting';
import Invoice from '../admin/invoice/Invoice';
import AddInvoice from '../admin/invoice/AddInvoice';
import EditInvoice from '../admin/invoice/EditInvoice';
import TableComponent from '../admin/TableComponent';
import ManageClients from '../admin/clients/ManageClients';
import ManageVendors from '../admin/vendors/ManageVendors';
import { AuthContext } from '../context/AuthContext';
import NoAccess from '../NoAccess';
import ManageProjects from '../admin/projects/ManageProjects';
import ManageResources from '../admin/resources/ManageResources';
import Dashboard from '../admin/dashboard/Dashboard';
import VenderPaymentList from '../admin/vendors/VenderPaymentList';
import ManageExchange from '../admin/exchange/ManageExchange';
import ManageReport from '../admin/report/ManageReport';

function NavRoutes() {
 

  return (
    <Routes>
      {/* <Route path="/" element={isAuthenticated() ? <Dashboard /> : <Navigate to="/login" />} /> */}
      <Route exact path="/login" element={<Login />} />
      
      <Route exact path="/forget-password" element={<ForgotPassword />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route path="/" element={<AuthRoutes />}>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path={CONST.ADMIN_PRE + 'dashboard'} element={<Dashboard />} />
        <Route exact path={CONST.ADMIN_PRE + 'profile'} element={<Profile />} />
        <Route exact path={CONST.ADMIN_PRE + 'invoice'} element={<Invoice />} />
        <Route exact path={CONST.ADMIN_PRE + 'invoice/add'} element={<AddInvoice />} />
        <Route exact path={CONST.ADMIN_PRE + 'invoice/edit/:invoice_id'} element={<EditInvoice />} />
        <Route exact path={CONST.ADMIN_PRE + 'manage-users'} element={<UserList />} />
        <Route exact path={CONST.ADMIN_PRE + 'manage-projects'} element={<ManageProjects />} />
        <Route exact path={CONST.ADMIN_PRE + 'setting'} element={<Setting />} />
        <Route exact path={CONST.ADMIN_PRE + 'table'} element={<TableComponent />} />
        <Route exact path={CONST.ADMIN_PRE + 'manage-clients'} element={<ManageClients />} />
        <Route exact path={CONST.ADMIN_PRE + 'manage-vendors'} element={<ManageVendors />} />
        <Route exact path={CONST.ADMIN_PRE + 'vendor/payments'} element={<VenderPaymentList />} />
        <Route exact path={CONST.ADMIN_PRE + 'manage-resources'} element={<ManageResources />} />
        <Route exact path={CONST.ADMIN_PRE + 'manage-exchange'} element={<ManageExchange />} />
        <Route exact path={CONST.ADMIN_PRE + 'manage-reports'} element={<ManageReport />} />
        <Route exact path={CONST.ADMIN_PRE + 'no-access'} element={<NoAccess />} />
      </Route>

    </Routes>
  )
}

export default NavRoutes