import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import BlockLoading from "../../../blocks/BlockLoading";
import * as CONST from "../../../includes/Constant";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../../includes/staticData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const COLORS_FIXEDCOST = ["#34D399", "#10B981", "#047857"];
const COLORS_DEDICATED = ["#60A5FA", "#3B82F6", "#1D4ED8"];

const FixedcostVsDedicatedBarChart = ({ searchParams }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFixedcostVsDedicatedBarChart = async () => {
    try {
      setLoading(true);
      const response = await axios.get(CONST.DASH_FIXED_VS_DEDICATED_BAR_CHART, {
        params: searchParams
      });
      if (response.data && Array.isArray(response.data.data)) {
        setChartData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFixedcostVsDedicatedBarChart();
  }, [searchParams]);

  if (loading) return <div className="row"><BlockLoading /></div>;

  const labels = chartData.map(item => item.Month || "Unknown");

  const getValue = (item, path) => {
    return path.split('.').reduce((acc, key) => (acc ? acc[key] : 0), item) || 0;
  };

  const datasets = [
    {
      label: "Fixed Cost Raised",
      data: chartData.map(item => getValue(item, "fixedcost.Raised")),
      backgroundColor: COLORS_FIXEDCOST[2],
      stack: "fixedcost",
    },
    {
      label: "Fixed Cost Considered",
      data: chartData.map(item => getValue(item, "fixedcost.Considered")),
      backgroundColor: COLORS_FIXEDCOST[1],
      stack: "fixedcost",
    },
    {
      label: "Fixed Cost Received",
      data: chartData.map(item => getValue(item, "fixedcost.Received")),
      backgroundColor: COLORS_FIXEDCOST[0],
      stack: "fixedcost",
    },
    {
      label: "Dedicated Raised",
      data: chartData.map(item => getValue(item, "dedicated.Raised")),
      backgroundColor: COLORS_DEDICATED[2],
      stack: "dedicated",
    },
    {
      label: "Dedicated Considered",
      data: chartData.map(item => getValue(item, "dedicated.Considered")),
      backgroundColor: COLORS_DEDICATED[1],
      stack: "dedicated",
    },
    {
      label: "Dedicated Received",
      data: chartData.map(item => getValue(item, "dedicated.Received")),
      backgroundColor: COLORS_DEDICATED[0],
      stack: "dedicated",
    },
  ];

  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${S_FORMAT_INR_CURRENCY_AND_AMOUNT(context.raw)}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => S_FORMAT_INR_CURRENCY_AND_AMOUNT(value),
        },
        grid: {
          borderDash: [3],
        },
      },
    },
  };

  return (
    <div className="card flex-fill w-100">
      <div className="card-header">
        <h5 className="card-title mb-0">Fixed Cost vs Dedicated (Month-wise)</h5>
      </div>
      <div className="card-body pt-2 pb-3" style={{ height: 400 }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default FixedcostVsDedicatedBarChart;
