import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { Link } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { AuthContext } from '../../context/AuthContext';

import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import useAuthInfo from "../../../hooks/IsAdmin";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT, S_INR_CURRENCY_SYMBOL, SD_FY_MONTHS, SD_INVOICE_STATUS } from "../../includes/staticData";
import { setGlobalModal } from "../modal/GlobalModal";

import ViewInvoice from "../modal/invoice/ViewInvoice";
import ViewClient from "../modal/client/ViewClient";
import InvoicePayments from "../invoice/InvoicePayments";
import InvoiceHistory from "../invoice/InvoiceHistory";


const ManageReport = () => {
  console.log(S_FORMAT_INR_CURRENCY_AND_AMOUNT('500'))

  const { isAdmin, eDepartmentId } = useAuthInfo();
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("inv.id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [paymentsInvoiceId, setPaymentsInvoiceId] = useState();
  const [invoiceStatus, setInvoiceStatus] = useState();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInvoiceHistoryModal, setShowInvoiceHistoryModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(authUserData ? authUserData[0].user_id : 1);
  /* SEARCH VARIABLES */
  const [financialYears, setFinancialYears] = useState([]);
  const [currentFinancialYears, setCurrentFinancialYears] = useState([]);
  const [status, setStatus] = useState('pending');
  const [selectedYear, setSelectedYear] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [userDepartment, setUserDepartment] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);
  useEffect(() => {
    if (!isAdmin && departmentData.length > 0) {
      const matchedDept = departmentData.find((d) => d.value === eDepartmentId);
      //console.log(matchedDept);
      if (matchedDept) {
        setUserDepartment(matchedDept);
        setDepartments(matchedDept);
        setSearchParams((prev) => ({
          ...prev,
          department_id: eDepartmentId
        }));
        fetchClientData(eDepartmentId);
      }
    }
  }, [isAdmin, departmentData]);


  useEffect(() => {
    if (searchParams.financialYear) {
      fetchData();
    }
    //fetchData();
  }, [currentPage, recordsPerPage, sortColumn, sortOrder, searchParams]);

  useEffect(() => {
    const generateFinancialYears = () => {
      const startYear = 2023; // Start from 2024-2025
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      let financialYearsList = [];
      let endYear = currentMonth >= 4 ? currentYear : currentYear - 1;

      // Loop from latest year to startYear to make latest year appear first
      for (let year = endYear; year >= startYear; year--) {
        financialYearsList.push({
          value: `${year}-${year + 1}`,
          label: `${year}-${year + 1}`,
        });
      }

      setFinancialYears(financialYearsList);

      // Set the current financial year as default selection
      const currentFinancialYear = `${endYear}-${endYear + 1}`;
      /* setCurrentFinancialYears({
        currentFinancialYear,

      }); */
      const selectedFY = financialYearsList.find(year => year.value === currentFinancialYear);
      setSearchParams((prev) => ({
        ...prev,
        financialYear: currentFinancialYear
      }));
      setSelectedYear(selectedFY);
      setCurrentFinancialYears(selectedFY);
    };

    fetchDepartmentData();
    generateFinancialYears();
    //fetchClientData();
  }, []);

  const fetchDepartmentData = async () => {
    try {
      const departmentResponse = await axios.get(CONST.GET_DEPARTMENT_LIST, {
        params: { is_invoice: 0 }
      });
      if (departmentResponse.data.status === 'success') {
        setDepartmentData(departmentResponse.data.data.map(department => ({ value: department.id, label: department.name })));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
  };

  const fetchClientData = async (departmentId) => {
    setLoading(true);
    try {
      const clientsResponse = await axios.get(CONST.GET_ALL_CLIENT, {
        params: departmentId ? { department_id: departmentId } : {},
      });
      //const clientsResponse = await axios.get(CONST.GET_ALL_CLIENT);
      if (clientsResponse.data.status === 'success') {
        setClients(clientsResponse.data.data.map(client => ({
          value: client.id,
          label: client.name,
        })));
      }
    } catch (error) {
      toast.error('Error:', error);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONST.GET_INVOICE_LIST, {
        params: {
          page: currentPage,
          per_page: recordsPerPage,
          sort_by: sortColumn,
          sort_order: sortOrder,
          client_id: searchParams.client,
          status: searchParams.status,
          invoice_date: searchParams.invoice_date,
          financial_year: searchParams.financialYear,
          department_id: searchParams.department_id,
          invoice_id: searchParams.invoice_id
        }
      });
      if (response.data) {
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {

      setData([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };



  const getCurrencySymbol = (currencyCode) => {
    return (0).toLocaleString("en", { style: "currency", currency: currencyCode }).replace(/\d|,|\.|\s/g, '');
  };
  const formatINRCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const invoiceStatusOptions = SD_INVOICE_STATUS.map((invoiceStatus) => ({
    label: invoiceStatus.name,
    value: invoiceStatus.code,
  }));

  /* TABLE FUNCTIONS  */
  const actionFormatter = (cell, row) => {
    return (
      <>

        {
          (row && (row.status === 1 || row.status === 0)) ? (
            <>
              <Link to={CONST.ADMIN_PRE + 'invoice/edit/' + row.invoice_id} className="action">
                <i className='bx bx-pencil'></i>
              </Link>
            </>
          ) : ""
        }
        {
          row && (row.status !== 1 && row.status !== 4) && (
            <>
              <Link to="#" className="action" onClick={() => handlePayment(row.invoice_id, row.status, row)}>
                <i className='bx bx-wallet'></i>
              </Link>
            </>
          )
        }
        {
          (row.pdf_link && row.pdf_link && row.status !== 1) ?
            <>
              <Link to="#" className="action" onClick={() => handleView(row.pdf_link)}>
                <i className='bx bx-show'></i>
              </Link>
              <Link to="#" className="action" onClick={() => handleDownload(row.pdf_link)}>
                <i className='bx bx-download' ></i>
              </Link>
            </> : ""
        }
        <Link to="#" className="action" onClick={() => handleInvoiceHistory(row.invoice_id)}>
          <i className='bx bx-history'></i>
        </Link>

      </>
    );
  };
  const columns = [
    {
      dataField: "invoice_number",
      text: "Invoice #",
      sortable: true,
      onSort: () => handleSort("invoice_number"),
      formatter: (cell, row) => {
        const invoice_number = row.invoice_number || '';
        const client_name = row.client?.name || "N/A";
        const invoice_id = row.invoice_id || null;
        return (
          <>
            <Link to="#" className="action"
              onClick={() =>
                setGlobalModal(
                  ViewInvoice,
                  {
                    invoice_id: invoice_id,
                  },
                  {  // Modal Props
                    size: "xl",
                    title: "#" + invoice_number + " - " + client_name
                  }
                )
              }
            >
              {invoice_number}
            </Link >
          </>
        );
      }

    },
    {
      dataField: "client.name",
      text: "Client Info",
      sortable: true,
      onSort: () => handleSort("client.name"),
      formatter: (cell, row) => {
        const client_name = row.client?.name || "N/A";
        const client_email = row.client?.email || "N/A";
        const client_company_name = row.client?.company_name || "N/A";
        return (
          <>
            <Link to="#" className="action"
              onClick={() =>
                setGlobalModal(
                  ViewClient,
                  {
                    client_id: row.client_id,
                  },
                  {  // Modal Props
                    size: "lg",
                    title: "View Client " + client_name
                  }
                )
              }
            >
              {client_name}
            </Link >
            <br /><small>{client_company_name}</small> <br /> <small>{client_email}</small>
          </>
        );
      }
    },
    {
      dataField: "total_amount",
      text: "Amount",
      sortable: true,
      onSort: () => handleSort("total_amount"),
      formatter: (cell, row) => {
        const total_gross_payment = row.total_gross_payment;
        const total_gst_amount = row.total_gst_amount;
        const total_amount = row.total_amount;
        const currency = S_INR_CURRENCY_SYMBOL(row.currency) ?? "$";
        if (row.client?.country === "IN") {
          return (
            <div style={{ textAlign: "right", fontSize: "12px" }}>
              <div><strong>Gross:</strong>  {S_FORMAT_INR_CURRENCY_AND_AMOUNT(total_gross_payment)}</div>
              <div><strong>GST:</strong> {S_FORMAT_INR_CURRENCY_AND_AMOUNT(total_gst_amount)}</div>
              <div><strong>Total:</strong>  {S_FORMAT_INR_CURRENCY_AND_AMOUNT(total_amount)}</div>
            </div>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{currency}{total_amount}</div>;
        }
      }
    },
    {
      dataField: "total_amount_inr",
      text: "Amount (INR)",
      sortable: true,
      onSort: () => handleSort("total_amount_inr"),
      formatter: (cell, row) => {
        const exchange_rate = row.exchange_rate || 0;
        const inr_payment = row.total_amount_inr || 0;

        if (row.client?.country === "IN") {
          return (
            <>
              {S_FORMAT_INR_CURRENCY_AND_AMOUNT(inr_payment)}
            </>
          );
        } else {
          return (
            <>
              {S_FORMAT_INR_CURRENCY_AND_AMOUNT(inr_payment)}
              <br />
              <small>ex rate: ({exchange_rate})</small>
            </>
          );
        }
      }
    }
    ,
    {
      dataField: "total_payments",
      text: "Received",
      sortable: true,
      onSort: () => handleSort("total_payments"),
      formatter: (cell, row) => {
        const totalPayments = row.total_payments ?? 0;
        const formattedAmount = S_FORMAT_INR_CURRENCY_AND_AMOUNT(totalPayments);
        const isHighlighted = parseInt(row.status) === 5 || row.is_considered === true;

        return (
          <div style={{ textAlign: "right" }}>
            {isHighlighted ? <strong>{formattedAmount}</strong> : formattedAmount}
          </div>
        );
      }
    }

    ,
    {
      dataField: "user_name",
      text: "Created By",
      sortable: true,
      onSort: () => handleSort("user_name"),
    },
    {
      dataField: "invoice_date",
      text: "Invoice Date",
      sortable: true,
      onSort: () => handleSort("invoice_date"),
      formatter: (cell) => {
        if (!cell) return "";
        const date = new Date(cell);
        return date.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" });
      }
    },
    {
      dataField: "status",
      text: "Status",
      sortable: true,
      onSort: () => handleSort("status"),
      formatter: (cell) => {
        const statusItem = SD_INVOICE_STATUS.find(item => item.code === String(cell));
        const statusText = statusItem ? statusItem.name : "Unknown";
        const statusClass = statusItem ? statusItem.color : "bg-secondary";

        return <span className={`badge ${statusClass}`}>{statusText}</span>;
      }
    },
    {
      dataField: "actions",
      text: "Actions",
      sortable: false,
      formatter: actionFormatter,
    },


  ];
  /* HANDLES */
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    /* setSearchParams((prev) => ({
      ...prev,
      financialYear: selectedOption.value
    })); */
  };

  const handleInvoiceChange = (selectedOption) => {
    console.log(selectedOption);
    setInvoiceId(selectedOption);
  };

  const handleSearch = () => {
    //console.log("departments" + departments);
    setSearchParams({
      client: client ? client.value : "",
      status: status ? status.value : "",
      invoice_date: invoiceDate
        ? invoiceDate.toLocaleDateString('en-CA') // 'YYYY-MM-DD' format
        : "",
      financialYear: selectedYear ? selectedYear.value : "",
      department_id: departments ? departments.value : "",
      invoice_id: invoiceId
    });
    /* fetchData(); */
  };
  const handleReset = () => {
    setLoading(true);
    console.log("reset");
    setClient(null);
    setStatus(null);
    setInvoiceDate(null);
    setSelectedYear(currentFinancialYears);
    setCurrentPage(1);
    setInvoiceId("");
    if (!isAdmin) {
      setSearchParams({
        client: "",
        status: "",
        invoice_date: "",
        financialYear: currentFinancialYears ? currentFinancialYears.value : "",
        department_id: departments ? departments.value : ""
      });
    } else {
      setDepartments(null);
      setSearchParams({
        client: "",
        status: "",
        invoice_date: "",
        financialYear: currentFinancialYears ? currentFinancialYears.value : "",
        department_id: ""
      });
    }

    //fetchData();
    setLoading(false);
  };

  const handleClientChange = (selectedOption) => {
    setClient(selectedOption);
  };
  const handleDepartmentChange = (selectedOption) => {
    setDepartments(selectedOption);
    //console.log(selectedOption?.value);
    fetchClientData(selectedOption?.value)
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleDateChange = (date) => {
    setInvoiceDate(date);
  };
  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };
  /* PDF VIEW & DOWNLOAD */
  const handleView = (pdf) => {
    setLoading(true);
    const iLink = CONST.PDF_LINK + pdf;
    window.open(iLink, "_blank");
    setLoading(false);
  };

  const handleDownload = (pdf) => {
    setLoading(true);
    const iLink = CONST.PDF_LINK + pdf;
    const link = document.createElement("a");
    link.href = iLink;

    // Extract filename from URL
    const fileName = iLink.split("/").pop();

    // using Java Script method to get PDF file
    fetch(iLink).then((response) => {
      response.blob().then((blob) => {

        // Creating new object of PDF file
        const fileURL =
          window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();

      });
    });
    setLoading(false);
  };
  /* Modal */
  const handlePayment = (invoice_id, invoice_status, row) => {
    setPaymentsInvoiceId(invoice_id);
    setInvoiceStatus(invoice_status);
    setSelectedInvoice(row);
    setShowPaymentModal(true);
  };
  const handleInvoiceHistory = (invoice_id) => {

    setPaymentsInvoiceId(invoice_id);
    setShowInvoiceHistoryModal(true);
  };
  const handlePaymentClose = () => {
    setShowPaymentModal(false);
    setSelectedInvoice("");
    fetchData();
  };
  const handleInvoiceHistoryModalClose = () => {
    setShowInvoiceHistoryModal(false);

  };
  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };
  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };


  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <div className="row mb-3">
          <Col md={9}>
            <h1 className="h3 mb-2">Manage <strong>Invoice</strong></h1>
          </Col>

          <Col md={3}>
            <Select
              options={financialYears}
              placeholder="Financial Year..."
              onChange={handleYearChange}
              value={selectedYear}
            />
          </Col>

        </div>
        <Row className="mb-2">
          {/* Financial Year Dropdown */}

          {
            (isAdmin) ? (
              <>
                <Col md={2} sm={4}>
                  <Select
                    options={departmentData}
                    placeholder="Department..."
                    onChange={handleDepartmentChange}
                    value={departments}
                  />
                </Col>
              </>
            ) : ""
          }
          <Col md={2} sm={4}>
            <Select
              options={SD_FY_MONTHS}
              placeholder="Select Month"
              onChange={handleMonthChange}
              value={selectedMonth}
            />
          </Col>
          {/* Client Dropdown */}
          <Col md={2} sm={4}>
            <Select
              options={clients}
              placeholder="client.."
              onChange={handleClientChange}
              value={client}
            />
          </Col>

          <Col md={2} sm={4}>
            <input
              type="text"
              className="form-control"
              name="invoice_number"
              onChange={(e) => setInvoiceId(e.target.value)}
              placeholder="Invoice Id"
              value={invoiceId}
            />
          </Col>

          {/* Date Picker (Fixed Placeholder) */}
          <Col md={2} sm={4}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={invoiceDate}
              onChange={handleDateChange}
              className="form-control"
              placeholderText="Invoice Date" // Corrected placeholder usage
            />
          </Col>
          <Col md={2} sm={4}>
            <Select
              options={invoiceStatusOptions}
              placeholder="Status..."
              onChange={handleStatusChange}
              value={status}
            />
          </Col>

          {/* Search & Reset Buttons */}
        </Row>

        <Row className="text-right">
          <Col md={4} sm={4} className="pt-3"></Col>
          <Col md={4} sm={4} className="pt-3">
            <Button variant="primary" onClick={handleSearch}>
              <i className='bx bx-search-alt'></i> {" "} Search
            </Button>
            <Button
              variant="secondary"
              onClick={handleReset}
              className="ml-2"
            >
              <i className='bx bx-x'></i> {" "} Reset
            </Button>
          </Col>
        </Row>


        <Row>

        </Row>
        <div className="row">

        </div>
        <div className="row mb-1">

          <div className="card flex-fill pt-3 pb-2">
            <div className="col-12">
              Showing total {totalRecords}
              <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select> records
            </div>

            <Table striped bordered hover>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.dataField}
                      onClick={() => col.sortable && handleSort(col.dataField)}
                      style={{ cursor: col.sortable ? "pointer" : "default" }}
                    >
                      {col.text}
                      {col.sortable && (sortColumn === col.dataField ? (sortOrder === "asc" ? " ▲" : " ▼") : "")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  data.length > 0 ? (data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col.dataField} >
                          {col.formatter ? col.formatter(item[col.dataField], item) : item[col.dataField]}
                        </td>
                      ))}
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </Table>
            <div className="row">
              <div className="col-md-4">
                Showing total {totalRecords}
                <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select> records
              </div>
              <div className="col-md-8 text-right">
                <Pagination className="text-right">
                  {[...Array(Math.ceil(totalRecords / recordsPerPage)).keys()].map((num) => (
                    <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                      {num + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
            </div>
          </div>
        </div>

        {/* Invoice Payment Modal */}
        <Modal
          show={showPaymentModal}
          onHide={() => handlePaymentClose()}
          size="xl"
          centered
          backdrop="static"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Manage Invoice Payments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InvoicePayments
              invoice_id={paymentsInvoiceId}
              onClose={() => handlePaymentClose()}
              currentUser={currentUser}
              invoiceStatus={invoiceStatus}
              selectedInvoice={selectedInvoice}
            />
          </Modal.Body>
        </Modal>
        {/* Invoice History Modal */}
        <Modal
          show={showInvoiceHistoryModal}
          onHide={() => handleInvoiceHistoryModalClose()}
          size="lg"
          centered
          backdrop="static"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Invoice History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InvoiceHistory
              invoice_id={paymentsInvoiceId}
              invoiceStatus={invoiceStatus}
            />
          </Modal.Body>
        </Modal>
      </div >
    </>
  );
};

export default ManageReport;
