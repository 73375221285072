import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import BlockLoading from "../../../blocks/BlockLoading";
import * as CONST from "../../../includes/Constant";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../../includes/staticData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

const STATUS_COLORS = {
  "Vendor Payment": "#E91E63",
  "Considered": "#6A5ACD",
  "Raised": "#FF9800",
  "Received": "#27ae60",
  "Partially Paid": "#17a2b8",
  "Dev sale": "#2ecc71",
  "Resources": "#03A9F4",
  "Platform Fee": "#FF0000",
};

const InvoiceStatusLineChart = ({ searchParams }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchInvoiceStatusLineChart = async () => {
    try {
      setLoading(true);
      const response = await axios.get(CONST.DASH_INVOICE_STATUS_LINE_CHART, {
        params: searchParams,
      });
      if (response.data && Array.isArray(response.data.data)) {
        setChartData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching invoice status chart:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceStatusLineChart();
  }, [searchParams]);

  if (loading) return <div className="row"><BlockLoading /></div>;

  const months = chartData.map((item) => item.month);
  const lineKeys = Object.keys(chartData[0] || {}).filter((key) => key !== "month");

  const datasets = lineKeys.map((key) => {
    const color = STATUS_COLORS[key] || "#64748b";
    return {
      label: key,
      data: chartData.map((entry) => entry[key] || 0),
      fill: true,
      backgroundColor: `${color}33`, // 20% opacity
      borderColor: color,
      pointBorderColor: color,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 2,
      tension: 0.3,
    };
  });

  const data = {
    labels: months,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.parsed.y;
            return `${context.dataset.label}: ${S_FORMAT_INR_CURRENCY_AND_AMOUNT(value)}`;
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => S_FORMAT_INR_CURRENCY_AND_AMOUNT(value),
        },
        grid: {
          drawBorder: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div className="card flex-fill w-100">
      <div className="card-header">
        <h5 className="card-title mb-0">FY Movement</h5>
      </div>
      <div className="card-body pt-2 pb-3" style={{ height: 450 }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default InvoiceStatusLineChart;
