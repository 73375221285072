import React, { useContext, useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
import * as CONST from "../../includes/Constant";
import { Link } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import { toast } from "react-toastify";
import { S_INR_CURRENCY_SYMBOL, S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../includes/staticData";
import DatePicker from 'react-datepicker'
import Swal from "sweetalert2";

export default function VendorPaymentHistory({ vendor_id, currentUser }) {
  //console.log(S_INR_CURRENCY_SYMBOL('inr'));
  //console.log(S_FORMAT_INR_CURRENCY_AND_AMOUNT('5000'));

  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("u.id");
  const [sortOrder, setSortOrder] = useState("desc");

  /* SEARCH VARIABLES */
  const [userName, setUserName] = useState("");
  const [financialYears, setFinancialYears] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [email, setEmail] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState([]);
  const [editVendor, setEditVendor] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  /* Inline Editing */
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editAmount, setEditAmount] = useState("");
  const [editDate, setEditDate] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);

  const fetchVendorPaymentHistory = async () => {
    setLoading(true);
    try {
      //console.log(searchParams);
      const response = await axios.get(CONST.GET_VENDOR_PAYMENT_HISTORY, {
        params: {
          page: currentPage,
          per_page: recordsPerPage,
          sort_by: sortColumn,
          sort_order: sortOrder,
          name: searchParams.name || "",
          status: searchParams.status || "",
          vendor_id: vendor_id || "",
          year: searchParams.financialYear || "",
        }
      });
      if (response.data) {
        setData(response.data.data);
        setTotalRecords(response.data.total);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const generateFinancialYears = () => {
      const startYear = 2024; // Start from 2024-2025
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      let financialYearsList = [];
      let endYear = currentMonth >= 4 ? currentYear : currentYear - 1;

      // Loop from latest year to startYear to make latest year appear first
      for (let year = endYear; year >= startYear; year--) {
        financialYearsList.push({
          value: `${year}-${year + 1}`,
          label: `${year}-${year + 1}`,
        });
      }

      setFinancialYears(financialYearsList);

      // Set the current financial year as default selection
      const currentFinancialYear = `${endYear}-${endYear + 1}`;
      const selectedFY = financialYearsList.find(year => year.value === currentFinancialYear);

      setSelectedYear(selectedFY);
      setSearchParams((prev) => ({
        ...prev,
        financialYear: currentFinancialYear
      }));
    };
    generateFinancialYears();
  }, [])

  useEffect(() => {
    fetchVendorPaymentHistory();
  }, [currentPage, recordsPerPage, sortColumn, sortOrder, searchParams])

  /* Edit */
  const handleInlineEdit = (index, row) => {
    setEditingRowIndex(index);
    setEditAmount(row.amount);
    setEditDate(new Date(row.payment_month).toISOString().slice(0, 10)); // Format: yyyy-mm-dd
  };

  const handleCancelEdit = () => {
    setEditingRowIndex(null);
    setEditAmount("");
    setEditDate("");
  };

  const handleSaveInlineEdit = async (row) => {
    try {
      //console.log(editDate)
      /* const formattedDate = editDate.toISOString().slice(0, 7); */
      const date = new Date(editDate);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      const payload = {
        id: row.id,
        amount: editAmount,
        payment_date: formattedDate,
        updated_by: currentUser
      };

      await axios.post(CONST.UPDATE_VENDOR_PAYMENT, payload); // Add your correct API endpoint

      toast.success("Payment updated successfully.");
      setEditingRowIndex(null);
      fetchVendorPaymentHistory(); // Reload table
    } catch (err) {
      console.error(err);
      toast.error("Failed to update payment.");
    }
  };


  /* TABLE FUNCTIONS  */
  const actionFormatter = (cell, row, rowIndex) => {
    const isEditing = editingRowIndex === rowIndex;
    return (
      <>
        {
          isEditing ? (
            <>
              <Button variant="primary" size="sm" onClick={() => handleSaveInlineEdit(row)}>
                Save
              </Button>{" "}
              <Button variant="secondary" size="sm" onClick={handleCancelEdit}>
                Cancel
              </Button>
            </>
          ) :
            (
              <>
                <Link to="#" className="action" onClick={() => handleInlineEdit(rowIndex, row)}
                >
                  <i class='bx bx-pencil'></i>
                </Link>
                <Link to="#" className="action" onClick={() => handleDelete(row.id)}>
                  <i class='bx bx-trash'></i>
                </Link>
              </>
            )

        }
      </>
    )
  }
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(CONST.DELETE_VENDOR_PAYMENT, { id: id })
          .then((response) => {
            if (response.data.status === "success") {
              fetchVendorPaymentHistory();
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error("Error deleting payment:", error);
            toast.error("Something went wrong. Please try again.");
          });
      }
    });
  };
  const columns = [
    {
      dataField: "index",
      text: "Sr.no",
      formatter: (cell, row, rowIndex) => {
        return (currentPage - 1) * recordsPerPage + (rowIndex + 1);
      },
      sortable: false
    },

    {
      dataField: "amount",
      text: "Amount",
      sortable: true,
      onSort: () => handleSort("amount"),
      formatter: (cell) => {
        if (!cell) return "";
        return S_FORMAT_INR_CURRENCY_AND_AMOUNT(cell);
      }
    },
    {
      dataField: "added_by_name",
      text: "Added By",
      sortable: true,
      onSort: () => handleSort("added_by_name"),
    },
    {
      dataField: "payment_month",
      text: "Date",
      sort: true,
      onSort: () => handleSort("payment_month"),
      formatter: (cell) => {
        if (!cell) return "";
        const date = new Date(cell);
        return date.toLocaleDateString("en-GB", { month: "short", year: "numeric" }); // ➜ "Feb 2025"
      }
    },
    {
      dataField: "actions",
      text: "Actions",
      sortable: false,
      formatter: actionFormatter,
    },


  ];
  /* HANDLES */
  const handleSearch = () => {
    setSearchParams({
      name: userName ? userName : "",
      status: status ? status.value : "",
      financialYear: selectedYear ? selectedYear.value : "",
    });
    // fetchData();
  };
  const handleReset = () => {
    setUserName("");
    setStatus("")
    setSearchParams({
      name: "",
      status: "",
      financialYear: selectedYear ? selectedYear.value : "",
    });
    setCurrentPage(1);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };
  const handleEdit = (data) => {
    setEditVendor(data);
    setShowModal(true);
  };

  const handlePageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  /* HANDLES */
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    /*  setSearchParams((prev) => ({
       ...prev,
       financialYear: selectedOption.value
     })); */
  };


  return (
    <>
      <Loading loading={loading} background="rgba(0, 0, 0, 0.5)" loaderColor="#fff" />
      <div className="container-fluid p-0">
        <Row className="mb-3 " >
          {/* Financial Year Dropdown */}
          <Col className="col-md-4">
            <Select
              options={financialYears}
              placeholder="Financial Year..."
              onChange={handleYearChange}
              value={selectedYear}
            />
          </Col>
          {/* <Col>
            <Form.Control
              type="text"
              placeholder="Search Name"
              value={userName}
              onChange={handleUserNameChange}
            />
          </Col>

          <Col>
            <Select
              options={[
                { value: "0", label: "Active" },
                { value: "1", label: "InActive" },
                { value: "2", label: "Deleted" },
              ]}
              placeholder="Select Status..."
              onChange={handleStatusChange}
              value={status}
            />
          </Col> */}

          {/* Search & Reset Buttons */}
          <Col xs="auto">
            <Button variant="primary" onClick={handleSearch}>
              <i className='bx bx-search-alt'></i> {" "} Search
            </Button>
            <Button
              variant="secondary"
              onClick={handleReset}
              className="ml-2"
            >
              <i className='bx bx-x'></i> {" "} Reset
            </Button>
          </Col>
        </Row>

        <div className="row mb-1">
          <div className="card flex-fill pt-3 pb-2">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th key={col.dataField} onClick={col.onSort}>
                      {col.text}
                      {sortColumn === col.dataField && (sortOrder === "asc" ? " ▲" : " ▼")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col) => {
                        const isEditing = editingRowIndex === index;
                        if (isEditing && (col.dataField === "amount" || col.dataField === "payment_month")) {
                          return (
                            <td key={col.dataField}>
                              {col.dataField === "amount" ? (
                                <Form.Control
                                  type="number"
                                  value={editAmount}
                                  onChange={(e) => setEditAmount(e.target.value)}
                                />
                              ) : (
                                <DatePicker
                                  selected={editDate}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  onChange={(date) => setEditDate(date)}
                                />

                              )}
                            </td>
                          );
                        } else {
                          return (
                            <td key={col.dataField}>
                              {col.formatter ? col.formatter(item[col.dataField], item, index) : item[col.dataField]}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      No records found.
                    </td>
                  </tr>
                )}
              </tbody>


            </Table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            Showing total {totalRecords}
            <select className="m-2" onChange={handlePageChange} value={recordsPerPage}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> records
          </div>
          <div className="col-md-8 text-right">
            <Pagination className="text-right">
              {[...Array(Math.ceil(totalRecords / recordsPerPage)).keys()].map((num) => (
                <Pagination.Item key={num + 1} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
                  {num + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
      </div>
    </>
  )
}
